import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Col, Card } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesSYPCen: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should You Pull Cen Ying?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_cen.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should You Pull Cen Ying?</h1>
          <h2>
            A dedicated guide for Cen Ying that will help you decide whether to
            pull her or not.
          </h2>
          <p>
            Last updated: <strong>06/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                <strong>The core of the Summoner team and its main DPS</strong>,
                Cen Ying has retained a spot for herself across the many patches
                of Ash Echoes CN, both in her own team and as an interception
                specialist. Although she may have spent some time in the
                backseat as new DPS characters drew the attention to other
                teams. However, the recent addition of Midas on Ash Echoes CN
                proves that the Summoner team is still very much alive and well.
              </p>
              <p>
                <strong>
                  Cen Ying is one of the most accessible main carries for
                  beginners
                </strong>
                , all thanks to her versatility, straightforward gameplay, not
                having to worry about elemental reactions (while benefiting from
                their effects), and a very cheap and easy build on launch
                composed mostly of free Memory Traces.
              </p>
              <p>
                Regardless of all these benefits, Ash Echoes is still a game
                where different teams provide different experiences. To see if
                Cen Ying is the right pick for you, check out our detailed guide
                below.
              </p>
              <StaticImage
                src="../../../images/ash/pull/cen_1.webp"
                alt="Guides"
              />
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Review" />
              <p>
                <strong>
                  A Summoner through and through, Cen Ying's basic attacks are
                  delivered by her main drone, “Light Seeker.”
                </strong>{' '}
                This means that her basic attacks are affected by Nexus Skills
                and buffs that benefit summons, giving this young Archaeologist
                a head start even when she isn't using her skills.
              </p>
              <p>
                Every 30 seconds, Cen Ying summons a second Light Seeker drone
                to aid her on her auto-attacks. This secondary drone is affected
                by the level of her first skill “Stellar Parade,” a skill with a
                whopping 8 uses which summons 5 drones that stay on the field
                for 30 seconds or until they have attacked 24 times. Having a
                long range, the drones are very versatile: they can spread out
                to chase different enemies and projectiles or group up to attack
                a single enemy. Having an army easily at her disposal, Cen
                Ying’s damage and utility capabilities are further amplified by
                her second skill, “Captured on Camera,” which makes each Light
                Seeker hit up to 5 enemies, dealing damage and inflicting a
                [Dazzle] debuff on any non-mechanical enemies. [Dazzle] only
                lasts two seconds but makes the target miss all their basic
                attacks, helping with the team’s survivability.
              </p>
              <StaticImage
                src="../../../images/ash/pull/cen_2.webp"
                alt="Guides"
              />
              <p>
                <strong>
                  Finally, the cherry on top is Cen Ying’s ultimate, “Millennial
                  Voyage.” Cen Ying summons a ship with infinite range that
                  traverses the battlefield.
                </strong>{' '}
                Lasting 32 seconds and bombarding targeted areas every 0.8s,
                this is her main source of burst damage. As a Tactician, Cen
                Ying starts battle with her Seed Skill already 50% charged,
                making it easy for a speedy burst regardless if you’re playing
                the Summoner team or if you have her as a backup for another
                team and need someone to clear up a wave fast.
              </p>
              <p>
                However, as powerful as her kit is, no character is perfect.
                “Millennial Voyage” has a very long cooldown of 120s, and while
                Cen Ying’s damage is very stable, getting precise windows of
                burst damage can be tricky, especially since both her summons
                and her ultimate will prioritize projectiles and airborne
                enemies first. She also has trouble destroying barriers,
                breaking block gauges, and dealing extra damage to elite enemies
                and bosses.
              </p>
              <p>
                Despite these shortcomings, Cen Ying still ranks among the best
                when it comes to dealing with big waves, and her versatility
                makes her a good addition to any player account—be it as the
                core of Physical/Summoner teams or as a powerful backup for any
                other element.
              </p>
              <StaticImage
                src="../../../images/ash/pull/cen_3.webp"
                alt="Guides"
              />
              <h5>Pros & Cons</h5>
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Easy to build and use, thanks to the high amount of
                          free Traces that grant benefits to summons;
                        </li>
                        <li>
                          Versatile, can be used in any team as a backup to
                          clear up projectiles and large waves or
                          airborne/smaller enemies;
                        </li>
                        <li>
                          Remains relevant and adaptable throughout most
                          content, making her a reliable and lasting investment.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Relies too much on her ultimate for burst damage, and
                          her ultimate has a long cooldown and slow execution;
                        </li>
                        <li>
                          Struggles to deal with barriers and block gauges;
                        </li>
                        <li>
                          All her attacks and skills prioritize airborne enemies
                          and projectiles, making fights tricky when you need to
                          focus on an elite or specific target.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Gameplay Tips" />
              <h5>General Tips</h5>
              <ul>
                <li>
                  If you’re dealing with many enemies or projectiles, use
                  “Stellar Parade” right before unleashing “Millennial Voyage”
                  to maximize burst damage. That way, the Light Seekers from
                  “Stellar Parade” will clear away the projectiles and Airborne
                  units first, letting the ultimate focus all its damage
                  potential on the true threats.
                </li>
                <li>
                  Cen Ying has the largest range in the game (classified as
                  “Distant”). Take advantage of that to let her drones chase
                  down enemies while you kite them during Nexus Encounters and
                  to keep her safe during fights in general.
                </li>
              </ul>
              <h5>Dupes</h5>
              <p>
                <strong>
                  F2P players can get the full Cen Ying experience at 0 dupes.
                </strong>{' '}
                Spenders or those who really like Cen Ying and her type of team
                should invest all the way to the Dupe 3—“Go big or go home.” The
                reason behind this is because, while the first dupe gives a nice
                damage amplification to her ultimate and secondary Light
                Seekers, it’s still a small upgrade when compared to the third
                dupe. Dupe 3 lowers the cooldown of summoning a secondary Light
                Seeker from 30 seconds to 10 seconds, ensuring that Cen Ying
                will have multiple drones executing her auto-attacks at all
                times, and consequently increasing her personal damage output on
                the long run.
              </p>
              <h5>Skill Upgrade Priority</h5>
              <ul>
                <li>
                  <strong>
                    Skill 1, “Stellar Parade,” takes priority when deciding
                    which one to invest in first.
                  </strong>{' '}
                  Cen Ying’s combat trait and her secondary Light Seeker are
                  affected by the level of “Stellar Parade,” which means that
                  upgrading this skill will not only benefit her main source of
                  steady damage, but also her auto-attacks.
                </li>
                <li>
                  <strong>
                    Skill 2, (Captured on Camera), is also worth upgrading for
                    its cost-benefit in terms of cooldown vs damage.
                  </strong>{' '}
                  Keep in mind that its Dazzle debuff won’t work on mechanical
                  enemies, and this skill is more effective against large groups
                  of enemies, while, (Stellar Parade) can adapt and flock to a
                  single enemy if needed. If you have other high priority skills
                  to upgrade, then focus on them first.
                </li>
              </ul>
              <StaticImage
                src="../../../images/ash/pull/cen_4.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Recommendation" />
              <h5>Leader: Cen Ying</h5>
              <div className={`skills Physical`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Physical`}>
                          Leader Skill
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">
                            Damage Blessing - Diamond - ɑ
                          </p>
                          <p className="skill-type">
                            Classes: <strong>Tactician / Support</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`potential`}>
                        <p>
                          Increases Extra DMG by{' '}
                          <b>
                            10% (At Lv. 1) / 18% (At Lv. 2) / 25% (At Lv. 3)
                          </b>
                          . The Effect is doubled in a [Dry Environment]. It
                          does not stack with [Damage Blessing - Diamond - β].
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className={`skills Physical`}>
                <Row xs={1} xl={1} xxl={1}>
                  <Col>
                    <div className="box">
                      <div className="skill-header">
                        <div className={`skill-icon Physical`}>
                          Leader Skill
                        </div>
                        <div className="skill-info">
                          <p className="skill-name">Right Hand Man - Diamond</p>
                          <p className="skill-type">
                            Classes: <strong>Tactician / Support</strong>
                          </p>
                        </div>
                      </div>
                      <div className={`potential`}>
                        <p>
                          Increase the Extra DMG of own Summons by{' '}
                          <b>
                            30% (At Lv. 1) / 60% (At Lv. 2) / 100% (At Lv. 3).
                          </b>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <h5>Teams</h5>
              <p>
                NOTE: Remember you can mix and match Echomancers among all
                suggestions and alternate options, adjusting based on who you
                have available.
              </p>
              <div className={`character-teams box Physical`}>
                <div className="team-header">
                  <p>SUMMONER TEAM</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="luke"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="freda"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                A straightforward team. Cen Ying and Luke are obvious picks and
                need no introduction. Mo Hongxiu can create barriers, displace
                and group up enemies, helping to control the battlefield and
                direct burst windows.Freda is another easy pick for her buffs
                and ability to lower her teammates’ cooldowns, which helps with
                Cen Ying’s problem of having a long cooldown on her Seed Skill.
              </p>
              <div className={`character-teams box Physical`}>
                <div className="team-header">
                  <p>ALT. SUMMONER TEAM</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="sephonis"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="lorelle"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="longqing"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Lorelle has the ability to draw DMG Taken to herself while on
                Defense Mode, and buff all allies attacks when in Attack mode.
                She’s also useful for her gauge breaking capability, something
                the Summoner team usually struggles with. She can be paired with
                Sephonis. As a fellow summoner and Corrosion Echomancer,
                Sephonis benefits from most traces in the build and synergizes
                with Lorelle. If you’re pulling for Cen Ying, chances are you’ll
                get at least one copy of her.
              </p>
              <div className={`character-teams box Physical`}>
                <div className="team-header">
                  <p>BUDGET / NECROMANCER TEAM</p>
                </div>
                <div className="single-team">
                  <div className="team-row">
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="cen-ying"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Main DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="gauya"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role dps">Sub DPS</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="tian-ruo"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role tank">Tank</div>
                    </div>
                    <div className="with-role">
                      <div className="character main">
                        <Card className="avatar-card">
                          <AshCharacter
                            slug="su-xiao"
                            mode="card"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />
                        </Card>
                      </div>
                      <div className="role support">Support</div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                The usage of this team match is that Cen Ying stands in the
                front line to die once; Su Xiao can directly resurrect and then
                buff her ATK and CRIT until the end of the battle. Gauya's “Soul
                Queller" is considered a summon, and he’s also able to
                temporarily summon a ghost of an ally who died to fight.
              </p>
              <h5>More Alternate and Backup Options</h5>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="begonia"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Begonia</strong> [Sub-DPS]. Begonia’s “Wailing Bird” is
                considered a summon. Aside from benefiting from the summoner
                build herself, she can permanently buff the team’s ATK SPD by up
                to 50% (at dupe 1). Being a Striker, she also covers the problem
                of dealing with block gauges.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="tong"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Tong</strong> [Sub-DPS]. Similarly to Cen Ying, the
                floating cannons that Tong uses for his auto-attacks are
                considered summons. While he may not synergize with the team as
                well as Luke does, he still boasts great burst damage and an
                Auto-skill that disables enemies and increases their Physical
                DMG taken.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="roar"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Roar</strong> [Sub-DPS]. Much like Mo Hongxiu, Roar can
                trigger “Codename Diversity”, and help with dealing with waves
                of enemies if you don’t have Luke or Tong.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="shu"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Shu</strong> [Tank]. Can offer consistent AoE stun.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="rodney"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Rodney</strong> [Tank]. Offers taunt and a bit of
                damage. Like Shu, both are Physical Echomancers and can benefit
                from most Memory Traces from this build.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Memory Trace Recommendations" />
              <p>
                Below you can find the Memory Traces you should use on Cen Ying.
              </p>
              <h5>BIS</h5>
              <ul>
                <li>
                  <AshTrace
                    name="Beneath the Deception"
                    mode="data"
                    onProfile
                  />
                </li>
                <li>
                  <AshTrace name="Behind the Curtains" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Born from Ashes" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Bad Soil Bloom" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Nightmare" mode="data" onProfile /> (if with
                  Longqing or Tian Ruo)
                </li>
                <li>
                  <AshTrace name="Karma" mode="data" onProfile />
                </li>
              </ul>
              <h5>Alternatives and/or Budget options</h5>
              <ul>
                <li>
                  <AshTrace name="Happy Hours" mode="data" onProfile />{' '}
                  (Replaces “Bad Soil Bloom”)
                </li>
                <li>
                  <AshTrace name="Traces in the Museum" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Racing Night" mode="data" onProfile /> (if
                  with Luke)
                </li>
                <li>
                  <AshTrace name="Dawn" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Double Attraction" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Codename Diversity" mode="data" onProfile />
                </li>
                <li>
                  <AshTrace name="Wish in the Rain" mode="data" onProfile />
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Future Synergies" />
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="boreas"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Boreas</strong> [Support]. The next big addition to the
                Summoner team, Boreas can increase the CRIT Rate of summons by
                100% and enhance his allies’ Ice Element attacks, allowing for
                Cen Ying & co. to take part in a wider range of team comps and
                elemental reactions.
              </p>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="midas"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                <strong>Midas</strong> [DPS]. While still far away in the
                future, Midas is the next big Physical DPS. He synergizes very
                well with Cen Ying, as his own summon deals extra instances of
                damage every time his teammates’ summons attack.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Review</a>
                </li>
                <li>
                  <a href="#section-2">Gameplay Tips</a>
                </li>
                <li>
                  <a href="#section-3">Team Recommendation</a>
                </li>
                <li>
                  <a href="#section-4">Memory Trace Recs</a>
                </li>
                <li>
                  <a href="#section-5">Future Synergies</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesSYPCen;

export const Head: React.FC = () => (
  <Seo
    title="Should You Pull Cen Ying | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for Cen Ying that will help you decide whether to pull her or not."
    game="ash"
  />
);
